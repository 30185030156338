import React from 'react';
import {
  CircularProgress,
  Typography,
  Box,
  useTheme,
  useMediaQuery,
  GridWrap,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import InfiniteScroll from 'react-infinite-scroller';
import { styled } from '@mui/material/styles';

import { OnDemandPreviewFragment } from 'graphql/types';
import ContentTile from 'components/ContentTile';
import { uniqueId } from 'lodash';
import ContentPage from './onDemand/ContentPage';

const EmptyText = styled(Typography)(({ theme }) => ({
  marginTop: theme.baseUnit * 4,
  paddingLeft: '28px',
}));

const LoaderRow = styled(Box)(({ theme }) => ({
  padding: theme.baseUnit * 3,
  margin: theme.baseUnit * 2,
  flexBasis: '100%',
  textAlign: 'center',
}));

type RenderArgTypes = {
  item: OnDemandPreviewFragment;
  index: number;
};

const TileSkeletons = () =>
  new Array(12).fill(1).map(() => (
    <Grid size={{ xs: 4, lg: 3 }} key={uniqueId()}>
      <ContentTile skeleton />
    </Grid>
  ));

type ListProps = {
  handleOnEndReached?: () => void;
  pageInfo: any;
  loading: boolean;
  handleClick?: (item: OnDemandPreviewFragment) => void;
  contents: OnDemandPreviewFragment[];
  contentIdToShow?: string;
  mixpanelData?: Record<string, unknown>;
  wrap?: GridWrap; // allow having a horizontal scroll
  routePrefix?: string;
};

const ContentList = ({
  handleOnEndReached,
  pageInfo,
  loading,
  handleClick = () => {},
  contents,
  contentIdToShow,
  mixpanelData,
  wrap = 'wrap',
  routePrefix = 'ondemand',
}: ListProps) => {
  const muiTheme = useTheme();
  const isMdDown = useMediaQuery(muiTheme.breakpoints.down('lg'));

  const shouldLoadMore = pageInfo?.hasNextPage && !!handleOnEndReached;
  const loadMore = handleOnEndReached || (() => {});
  const renderItem = ({ item, index }: RenderArgTypes) => (
    <Grid
      size={{ xs: 4, lg: 3 }}
      key={`vid-${item.id}`}
      data-key={`vid-${item.id}`}
    >
      <ContentTile
        data-testid="class-item"
        contentId={item.id}
        onClick={() => {
          handleClick(item);
        }}
        title={item.title ?? ''}
        image={item.heroPhoto}
        mixpanelData={{
          ...mixpanelData,
          position: index,
        }}
        time={item.time}
        genre={item.contentGenre?.name}
        level={item.contentLevel?.name}
        type={item.__typename}
        saved={item.saved}
        routePrefix={routePrefix}
      />
    </Grid>
  );

  return (
    <>
      <Grid
        wrap={wrap}
        container
        spacing={isMdDown ? 2 : 3}
        component={InfiniteScroll}
        loadMore={loadMore}
        hasMore={shouldLoadMore}
        initialLoad={false}
        loader={
          <LoaderRow key="progress">
            <CircularProgress color="inherit" aria-label="Loading" />
          </LoaderRow>
        }
        display="flex"
      >
        {loading ? (
          <TileSkeletons />
        ) : contents.length ? (
          contents.map((item, index) => renderItem({ item, index }))
        ) : (
          <EmptyText>Nothing found.</EmptyText>
        )}
      </Grid>
      {contentIdToShow && contentIdToShow !== '' && <ContentPage />}
    </>
  );
};

export default ContentList;
