import React, { useCallback, useEffect } from 'react';

import { styled } from '@mui/material/styles';
import { IconButton, Button as MUIButton } from '@mui/material';
import useMixpanel from 'hooks/useMixpanel';
import { ModalContainer } from 'components/common/Modal';
import { Button } from 'components/common/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsDown, faThumbsUp } from '@fortawesome/pro-regular-svg-icons';
import {
  faThumbsUp as faThumbsUpSolid,
  faThumbsDown as faThumbsDownSolid,
} from '@fortawesome/free-solid-svg-icons';
import { FeedbackKind, useCreateContentFeedbackMutation } from 'graphql/types';
import { usePlaybookTheme } from 'playbook';

const Wrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  maxWidth: '326px',
  paddingLeft: theme.baseUnit * 2,
  paddingRight: theme.baseUnit * 2,
  paddingBottom: theme.baseUnit * 2,
  paddingTop: theme.baseUnit * 5,
  backgroundColor: theme.colors.alias.mainBackground,
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  borderRadius: theme.baseUnit,
}));

const ButtonContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  margin: 'auto',
  marginTop: theme.baseUnit * 3,
  marginBottom: theme.baseUnit * 3,
  gap: theme.baseUnit * 2,
}));

const Title = styled('h1')(({ theme }) => ({
  ...theme.typography.textVariant.title.s,
  color: theme.colors.alias.textPrimary,
  marginTop: 0,
  marginBottom: theme.baseUnit * 2,
}));

const DismissButton = styled(MUIButton)(({ theme }) => ({
  color: theme.colors.alias.textSecondary,
  margin: 'auto',
  width: 'fit-content',
  marginTop: theme.baseUnit * 2,
  '&:hover, &.Mui-focusVisible': {
    backgroundColor: 'transparent',
  },
}));

interface FeedbackModalProps {
  open: boolean;
  contentId?: string | null;
  type?: 'Video' | 'Audio' | 'Article';
  currentFeedback?: FeedbackKind | null;
  onClose: (selectedFeedback: FeedbackKind | null) => void;
}

const FeedbackModal: React.FC<FeedbackModalProps> = ({
  open,
  contentId,
  type,
  currentFeedback = null,
  onClose,
}) => {
  const track = useMixpanel();
  const theme = usePlaybookTheme();
  const [createFeedback] = useCreateContentFeedbackMutation();
  const [feedback, setFeedback] = React.useState<FeedbackKind | null>(
    currentFeedback,
  );

  useEffect(() => {
    if (open) {
      track('Screen View', {
        page_name: 'content_feedback_modal',
        content_id: contentId,
      });
    }
  }, [track, open, contentId]);

  const thumb = useCallback(
    (feedbackKind: FeedbackKind) => {
      const isPositive = feedbackKind === FeedbackKind.Positive;
      const eventName = isPositive ? 'thumbs_up_click' : 'thumbs_down_click';
      track('User Action', {
        event_category: 'on demand',
        event_location: 'content_feedback_modal',
        event_name: eventName,
        event_type: 'screen interaction',
        content_id: contentId,
      });
      setFeedback(feedbackKind);
    },
    [setFeedback, track, contentId],
  );

  const onSubmit = useCallback(() => {
    const isPositive = feedback === FeedbackKind.Positive;
    const eventLabel = isPositive ? 'positive' : 'negative';
    track('User Action', {
      event_category: 'on demand',
      event_location: 'content_feedback_modal',
      event_name: 'submit',
      event_type: 'screen interaction',
      event_label: eventLabel,
      content_id: contentId,
    });
    if (contentId && feedback && feedback !== currentFeedback) {
      createFeedback({
        variables: {
          contentId,
          feedback,
        },
      });
    } else {
      setFeedback(currentFeedback);
    }

    onClose(feedback);
  }, [track, contentId, feedback, currentFeedback, onClose, createFeedback]);

  const onDismiss = useCallback(() => {
    track('User Action', {
      event_category: 'on demand',
      event_location: 'content_feedback_modal',
      event_name: 'back_button_click',
      event_type: 'screen interaction',
      content_id: contentId,
    });

    if (contentId && !currentFeedback) {
      createFeedback({
        variables: {
          contentId,
          feedback: FeedbackKind.Needed,
        },
      });
    }
    onClose(null);
    setFeedback(currentFeedback);
  }, [track, contentId, currentFeedback, onClose, createFeedback]);

  return (
    <ModalContainer
      open={open}
      onClose={onDismiss}
      data-testid="content-feedback-modal"
    >
      <Wrapper>
        <Title>
          How did you like the {type === 'Article' ? 'article' : 'class'}?
        </Title>
        <ButtonContainer>
          <IconButton
            onClick={() => thumb(FeedbackKind.Positive)}
            aria-label="thumbs up"
          >
            <FontAwesomeIcon
              icon={
                feedback === FeedbackKind.Positive
                  ? faThumbsUpSolid
                  : faThumbsUp
              }
              color={theme.colors.alias.primary}
            />
          </IconButton>
          <IconButton
            onClick={() => thumb(FeedbackKind.Negative)}
            aria-label="thumbs down"
          >
            <FontAwesomeIcon
              icon={
                feedback === FeedbackKind.Negative
                  ? faThumbsDownSolid
                  : faThumbsDown
              }
              color={theme.colors.alias.primary}
            />
          </IconButton>
        </ButtonContainer>
        <Button onClick={onSubmit}>Submit</Button>
        <DismissButton variant="text" size="small" onClick={onDismiss}>
          Dismiss
        </DismissButton>
      </Wrapper>
    </ModalContainer>
  );
};

export default FeedbackModal;
