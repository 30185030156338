import React, { useCallback } from 'react';

import { styled } from '@mui/material/styles';
import useMixpanel from 'hooks/useMixpanel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsDown, faThumbsUp } from '@fortawesome/pro-regular-svg-icons';
import {
  faThumbsUp as faThumbsUpSolid,
  faThumbsDown as faThumbsDownSolid,
} from '@fortawesome/free-solid-svg-icons';

import { FeedbackKind, useCreateContentFeedbackMutation } from 'graphql/types';
import { HeaderButton } from 'components/common/HeaderButton';
import { useSnackbar } from 'contexts/snackbarContext';

const Wrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  paddingLeft: theme.baseUnit * 2,
  paddingRight: theme.baseUnit * 2,
  paddingBottom: theme.baseUnit * 2,
  paddingTop: theme.baseUnit * 5,
  backgroundColor: theme.colors.alias.mainSurface,
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  borderRadius: theme.baseUnit,
}));

const ButtonContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  margin: 'auto',
  marginTop: theme.baseUnit * 3,
  marginBottom: theme.baseUnit * 3,
  gap: theme.baseUnit * 2,
}));

const InvertedButton = styled(HeaderButton)(({ theme }) => ({
  '&:hover, &:focus-visible': {
    backgroundColor: theme.colors.alias.primary,
  },
}));

const Title = styled('h1')(({ theme }) => ({
  ...theme.typography.textVariant.title.s,
  color: theme.colors.alias.textPrimary,
  marginTop: 0,
  marginBottom: theme.baseUnit * 2,
}));

interface InlineFeedbackProps {
  contentId?: string | null;
  type?: 'Video' | 'Audio' | 'Article';
  currentFeedback?: FeedbackKind | null;
}

const InlineFeedback: React.FC<InlineFeedbackProps> = ({
  contentId,
  type,
  currentFeedback,
}) => {
  const track = useMixpanel();
  const [createFeedback] = useCreateContentFeedbackMutation();
  const snackbar = useSnackbar();

  const thumb = useCallback(
    (feedback: FeedbackKind) => {
      if (!contentId) return;

      snackbar.show('Thanks for rating!', 2000);
      const isPositive = feedback === FeedbackKind.Positive;
      const eventName = isPositive ? 'thumbs_up_click' : 'thumbs_down_click';
      track('User Action', {
        event_category: 'on demand',
        event_location: 'inline_content_feedback',
        event_name: eventName,
        event_type: 'screen interaction',
        content_id: contentId,
      });
      createFeedback({
        variables: {
          contentId,
          feedback,
        },
      });
    },
    [contentId, snackbar, track, createFeedback],
  );

  return (
    <Wrapper data-testid="inline-content-feedback">
      <Title>
        How did you like the {type === 'Article' ? 'article' : 'class'}?
      </Title>
      <ButtonContainer>
        <InvertedButton
          size={48}
          onClick={() => thumb(FeedbackKind.Positive)}
          aria-label="thumbs up"
        >
          <FontAwesomeIcon
            icon={
              currentFeedback === FeedbackKind.Positive
                ? faThumbsUpSolid
                : faThumbsUp
            }
          />
        </InvertedButton>
        <InvertedButton
          size={48}
          onClick={() => thumb(FeedbackKind.Negative)}
          aria-label="thumbs down"
        >
          <FontAwesomeIcon
            icon={
              currentFeedback === FeedbackKind.Negative
                ? faThumbsDownSolid
                : faThumbsDown
            }
          />
        </InvertedButton>
      </ButtonContainer>
    </Wrapper>
  );
};

export default InlineFeedback;
