import React from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Route,
  RouterProvider,
} from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Container from 'components/Container';
import Evernorth from 'pages/login/Evernorth';
import Start from 'pages/login/Start';
import { LoginNonSSO } from 'pages/login/LoginNonSSO';
import Callback from 'pages/login/Callback';
import { ChangePassword } from 'pages/login/ChangePassword';
import { CompleteOnMobile } from 'pages/login/CompleteOnMobile';
import { supportedDeepLinks } from 'utils/deeplinks';
import * as Sentry from '@sentry/react';
import RedirectQR from 'pages/RedirectQR';

const deeplinks = Object.keys(supportedDeepLinks);

const GuestBrowserRouter = () => {
  /*
    NOTE: after add a new route, make sure to add it
    to the .pa11yci.js file to be included in the accessibility tests
  */
  const routesConfig = createRoutesFromElements(
    <Route>
      <Route path="/pepsico" element={<Evernorth />} />
      <Route
        element={
          <GuestLayout>
            <AuthenticationContainer maxWidth="xs">
              <Outlet />
            </AuthenticationContainer>
          </GuestLayout>
        }
      >
        <Route path="/" element={<Start />} />
        <Route path="/start/:email/:clientId?" element={<Start />} />
        <Route path="/login" element={<LoginNonSSO />} />
        <Route path="/complete-on-mobile" element={<CompleteOnMobile />} />
        <Route path="/callback" element={<Callback />} />
        <Route path="/forgot-password" element={<ChangePassword />} />
        <Route
          path="/password-expired"
          element={
            <ChangePassword message="Your password has expired. Please create a new password." />
          }
        />
        {/* Redirect To App */}
        {deeplinks.map((link) => (
          <Route path={link} key={link} element={<RedirectQR />} />
        ))}
        <Route path="*" element={<Start />} />
      </Route>
    </Route>,
  );

  const sentryCreateBrowserRouter =
    Sentry.wrapCreateBrowserRouterV6(createBrowserRouter);
  const browserRouter = sentryCreateBrowserRouter(routesConfig, {
    future: {
      v7_relativeSplatPath: true,
    },
  });
  return browserRouter;
};

const App = () => (
  <RouterProvider
    router={GuestBrowserRouter()}
    future={{ v7_startTransition: true }}
  />
);

export default App;

const GuestLayout = styled('div')({
  flex: 1,
  paddingTop: '7%',
});

const AuthenticationContainer = styled(Container)({
  width: 500,
  padding: 0,
});
