import React from 'react';
import styled from '@emotion/styled';
import { ClassFiltersT } from 'contexts/classFilterContext';
import { usePlaybookTheme } from 'playbook';
import { Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import FilterBtn from './FilterBtn';

const slug = (str: string): string => str.replace(/\s+/g, '-').toLowerCase();

const SectionContainer = styled.div(({ theme }) => ({
  borderBottom: `1px solid ${theme.colors.alias.mainBackgroundDivider}`,
}));

const SectionHeader = styled.button(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  flex: 1,
  paddingTop: theme.baseUnit * 2,
  paddingBottom: theme.baseUnit * 2,
  marginBottom: theme.baseUnit / 2,
  cursor: 'pointer',
  width: '100%',
  background: 'none',
  border: 'none',
}));

const SectionContents = styled.div(({ theme }) => ({
  flexWrap: 'wrap',
  flexDirection: 'row',
  paddingBottom: theme.baseUnit * 3,
}));

const TitleText = styled(Typography)(({ theme }) => ({
  ...theme.typography.textVariant.title.xs,
}));

type SectionProps = {
  title: string;
  isOpened: boolean;
  section: keyof ClassFiltersT;
  onToggleExpand: (section: keyof ClassFiltersT) => void;
  onToggleItem: (item: string) => void;
  items: string[];
  activeItems: string[];
};

const FilterSection: React.FC<React.PropsWithChildren<SectionProps>> = ({
  title,
  section,
  isOpened,
  items,
  onToggleExpand,
  onToggleItem,
  activeItems,
}) => {
  const theme = usePlaybookTheme();
  const onPress = () => onToggleExpand(section);

  return (
    <SectionContainer>
      <SectionHeader
        onClick={onPress}
        role="button"
        aria-label={`${title} section`}
        aria-expanded={isOpened}
      >
        <TitleText
          style={{
            textTransform: 'capitalize',
          }}
        >
          {title}
        </TitleText>
        <div
          style={{
            paddingTop: theme.baseUnit,
            paddingBottom: theme.baseUnit,
          }}
        >
          <FontAwesomeIcon
            icon={isOpened ? faAngleUp : faAngleDown}
            width={16}
            color={theme.colors.alias.iconPrimary}
          />
        </div>
      </SectionHeader>
      {isOpened && (
        <SectionContents>
          {items.map((item) => (
            <FilterBtn
              aria-label={item}
              key={item}
              testID={`item${slug(item)}`}
              text={item}
              checked={activeItems.includes(item)}
              onClick={() => {
                onToggleItem(item);
              }}
            />
          ))}
        </SectionContents>
      )}
    </SectionContainer>
  );
};

export default FilterSection;
