import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp } from '@fortawesome/pro-regular-svg-icons';
import {
  faThumbsUp as faThumbsUpSolid,
  faThumbsDown,
} from '@fortawesome/free-solid-svg-icons';
import useMixpanel from 'hooks/useMixpanel';
import styled from '@emotion/styled';
import { FeedbackKind } from 'graphql/types';
import { IconButton } from '@mui/material';

const DEFAULT_CONTAINER_SIZE = 26;
const DEFAULT_ICON_SIZE = 11;

const labelCopy = {
  [FeedbackKind.Needed]: 'Rate this content',
  [FeedbackKind.Negative]: 'Rated negatively',
  [FeedbackKind.Positive]: 'Rated positively',
  [FeedbackKind.Undecided]: 'Rate this content',
};

const Button = styled(IconButton, {
  // Prevent props from being passed down to the DOM, generating console errors
  shouldForwardProp: (prop) => !['containerSize'].includes(prop),
})<{
  containerSize: number;
}>(({ theme, containerSize }) => ({
  backgroundColor: theme.colors.alias.mainBackground,
  width: containerSize,
  height: containerSize,
  color: theme.colors.alias.iconPrimary,
  '&:hover, &:focus-visible': {
    backgroundColor: 'transparent',
    color: theme.colors.alias.textContrast,
    border: `1px solid ${theme.colors.alias.mainBackground}`,
    opacity: 1,
  },
  '.MuiTouchRipple-ripple .MuiTouchRipple-child': {
    backgroundColor: 'transparent',
  },
}));

const InvertedButton = styled(Button)(({ theme }) => ({
  border: `1px solid ${theme.colors.alias.primary}`,
  '&:hover, &:focus-visible': {
    backgroundColor: theme.colors.alias.primary,
    border: `1px solid ${theme.colors.alias.mainBackground}`,
    opacity: 1,
  },
}));

type FeedbackButtonProps = {
  id: string;
  currentFeedback?: FeedbackKind | null;
  inverted?: boolean;
  containerSize?: number;
  iconSize?: number;
  onClick: () => void;
  mixpanelData?: Record<string, unknown>;
};

const FeedbackButton: React.FC<FeedbackButtonProps> = ({
  id,
  currentFeedback,
  inverted = false,
  containerSize = DEFAULT_CONTAINER_SIZE,
  iconSize = DEFAULT_ICON_SIZE,
  onClick,
  mixpanelData,
}) => {
  const track = useMixpanel();

  const CurrentButton = inverted ? InvertedButton : Button;

  const handleClick = () => {
    let eventLabel;
    switch (currentFeedback) {
      case FeedbackKind.Positive:
        eventLabel = 'positive';
        break;
      case FeedbackKind.Negative:
        eventLabel = 'negative';
        break;
      default:
        eventLabel = undefined;
    }
    track('User Action', {
      ...mixpanelData,
      event_name: 'view_rating',
      event_label: eventLabel,
      contentId: id,
    });
    onClick();
  };

  let icon = faThumbsUp;
  if (currentFeedback === FeedbackKind.Negative) {
    icon = faThumbsDown;
  } else if (currentFeedback === FeedbackKind.Positive) {
    icon = faThumbsUpSolid;
  }

  return (
    <CurrentButton
      onClick={handleClick}
      aria-label={
        currentFeedback
          ? labelCopy[currentFeedback]
          : labelCopy[FeedbackKind.Needed]
      }
      containerSize={containerSize}
    >
      <FontAwesomeIcon icon={icon} width={iconSize} />
    </CurrentButton>
  );
};

export default FeedbackButton;
