import React, { useEffect } from 'react';
import {
  OnDemandPreviewFragment,
  useGetUserSavedContentLazyQuery,
} from 'graphql/types';
import ContentList from 'components/ContentList';
import SectionTitle from 'components/common/SectionTitle';
import { usePlaybookTheme } from 'playbook';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import useMixpanel from 'hooks/useMixpanel';
import { startViewTransition } from 'utils/helpers';
import { getOnDemandClickEventName } from './utils';

const ViewAllButton = styled('button')(({ theme }) => ({
  ...theme.typography.textVariant.button.m,
  color: theme.colors.alias.textPrimary,
  backgroundColor: 'transparent',
  border: 'none',
  cursor: 'pointer',
  '&:hover': {
    textDecoration: 'underline',
  },
  padding: 0,
}));

const SavedSection = () => {
  const theme = usePlaybookTheme();
  const navigate = useNavigate();
  const track = useMixpanel();

  const [fetchContent, { data, loading }] = useGetUserSavedContentLazyQuery({
    variables: {
      connectionInput: {
        first: 4,
      },
    },
  });

  useEffect(() => {
    fetchContent();
  }, [fetchContent]);

  const shouldShowViewAll = data?.self?.savedContent.pageInfo.hasNextPage;

  const onModalOpen = (item: OnDemandPreviewFragment) => {
    const position = contents.findIndex((c) => c.id === item.id);

    track('User Action', {
      event_name: getOnDemandClickEventName(item.__typename),
      event_type: 'screen interaction',
      event_category: 'on demand',
      event_subcategory: 'saved',
      event_location: 'home',
      content_id: item.id,
      section_id: 'exos:fit-saved-classes',
      position,
    });
  };

  const onClickViewAll = () => {
    track('User Action', {
      event_category: 'on demand',
      event_location: 'home',
      event_name: 'view_all_classes',
      event_subcategory: 'saved',
      event_type: 'passive engagement',
      section_id: 'exos:fit-saved-classes',
    });
    startViewTransition();
    navigate('/saved');
  };

  const contents = (data?.self?.savedContent.nodes ??
    []) as OnDemandPreviewFragment[];

  if (contents.length === 0) {
    return null;
  }

  return (
    <div style={{ marginBottom: theme.baseUnit * 6 }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: theme.baseUnit,
        }}
      >
        <SectionTitle role="heading" aria-level={2}>
          Saved
        </SectionTitle>
        {shouldShowViewAll && (
          <ViewAllButton onClick={onClickViewAll}>View All</ViewAllButton>
        )}
      </div>

      <div style={{ overflowX: 'auto' }}>
        <ContentList
          contents={contents}
          loading={loading}
          pageInfo={data?.self?.savedContent.pageInfo}
          handleClick={(item) => onModalOpen(item)}
          mixpanelData={{
            event_location: 'home',
            event_subcategory: 'saved',
            section_id: 'exos:fit-saved-classes',
          }}
          wrap="nowrap"
        />
      </div>
    </div>
  );
};

export default SavedSection;
