import React, { useEffect, useState } from 'react';
import {
  OnDemandPreviewFragment,
  useGetUserSavedContentLazyQuery,
} from 'graphql/types';
import { useNavigate, useParams } from 'react-router-dom';
import Container from 'components/Container';
import ContentList from 'components/ContentList';
import SectionTitle from 'components/common/SectionTitle';
import { Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark } from '@fortawesome/pro-regular-svg-icons';
import styled from '@emotion/styled';
import { Button } from 'components/common/Button';
import { selfSavedContentQueryUpdater } from 'utils/queryUpdaters';
import useMixpanel from 'hooks/useMixpanel';
import { usePlaybookTheme } from 'playbook';

const NoSavedContentWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  backgroundColor: theme.colors.alias.mainBackground,
  flex: 1,
  minHeight: theme.baseUnit * 50,
}));

const StyledContainer = styled(Container)(({ theme }) => ({
  backgroundColor: theme.colors.alias.mainBackground,
  paddingTop: theme.baseUnit * 3,
  marginTop: theme.baseUnit * 4,
}));

const NoSavedTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.textVariant.title.m,
  color: theme.colors.alias.textPrimary,
  marginBottom: theme.baseUnit * 2,
  marginTop: theme.baseUnit * 3,
}));

const NoSavedSubTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.textVariant.body.m,
  color: theme.colors.alias.textDisabled,
  marginBottom: theme.baseUnit * 5,
}));

const StickyHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  zIndex: 2,
  backgroundColor: theme.colors.alias.mainBackground,
  top: 0,
  marginBottom: theme.baseUnit * 4,
  paddingBottom: theme.baseUnit,
  paddingTop: theme.baseUnit * 3,
}));

const NoSavedContent = () => {
  const theme = usePlaybookTheme();
  const track = useMixpanel();
  const navigate = useNavigate();
  const onClick = () => {
    track('User Action', {
      event_category: 'on demand',
      event_location: 'saved_classes',
      event_name: 'start_saving',
      event_subcategory: 'saved',
      event_type: 'passive engagement',
    });
    navigate('/');
  };

  return (
    <NoSavedContentWrapper>
      <FontAwesomeIcon
        icon={faBookmark}
        style={{ height: theme.baseUnit * 6 }}
        color={theme.colors.alias.iconPrimary}
      />
      <NoSavedTitle role="heading" aria-level={2}>
        Nothing saved yet
      </NoSavedTitle>
      <NoSavedSubTitle>Start saving to your collection</NoSavedSubTitle>
      <Button onClick={onClick}>Browse</Button>
    </NoSavedContentWrapper>
  );
};

const SavedClasses = () => {
  const [fetchContent, { data, loading, fetchMore }] =
    useGetUserSavedContentLazyQuery();

  useEffect(() => {
    fetchContent();
  }, [fetchContent]);

  const { contentId } = useParams();

  const contents = (data?.self?.savedContent.nodes ??
    []) as OnDemandPreviewFragment[];

  const pageInfo = data?.self?.savedContent.pageInfo;
  const [contentIdToShow, setcontentIdToShow] = useState('');
  const track = useMixpanel();

  useEffect(() => {
    // make sure the page is scrolled to the top when the component mounts
    window.scrollTo(0, 0);

    track('Screen View', {
      page_name: 'saved_classes',
    });
  }, [track]);

  // REACT to location changes in contentId
  useEffect(() => {
    if (contentId) {
      setcontentIdToShow(contentId);
    } else {
      setcontentIdToShow('');
    }
  }, [contentId]);

  const handleOnEndReached = () => {
    if (pageInfo?.hasNextPage) {
      fetchMore({
        variables: {
          connectionInput: {
            after: pageInfo?.endCursor,
          },
        },
        updateQuery: selfSavedContentQueryUpdater,
      });
    }
  };

  return (
    <main>
      <div style={{ flex: 1, display: 'flex' }}>
        <StyledContainer>
          <StickyHeader>
            <SectionTitle role="heading" aria-level={2}>
              Saved
            </SectionTitle>
          </StickyHeader>

          {!loading && contents.length === 0 && <NoSavedContent />}

          {(loading || contents.length > 0) && (
            <ContentList
              contents={contents}
              loading={loading}
              handleOnEndReached={handleOnEndReached}
              pageInfo={pageInfo}
              contentIdToShow={contentIdToShow}
              mixpanelData={{
                event_location: 'saved_classes',
                event_subcategory: 'saved',
              }}
              routePrefix="saved"
            />
          )}
        </StyledContainer>
      </div>
    </main>
  );
};

export default SavedClasses;
