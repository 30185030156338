import React, { useCallback, useEffect, useMemo } from 'react';
import styled from '@emotion/styled';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CircularProgress, Divider, Modal, Typography } from '@mui/material';
import { Container } from '@mui/system';
import ClassTile from 'components/ContentTile';
import Header from 'components/common/Header';
import {
  FeedbackKind,
  OnDemandDetailsFragment,
  useGetOnDemandDetailsQuery,
} from 'graphql/types';
import {
  useLocation,
  useNavigate,
  useParams,
  useBlocker,
} from 'react-router-dom';
import useMixpanel from 'hooks/useMixpanel';
import { QR_REDIRECT_PATH } from 'pages/RedirectQR';
import BookmarkButton from 'components/common/BookmarkButton';
import CopyButton from 'components/common/CopyButton';
import { usePlaybookTheme } from 'playbook';
import HtmlContent from 'components/common/HtmlContent';
import { computeUrl } from 'utils/image';
import WistiaPlayer from 'components/common/WistiaPlayer';
import AudioPlayer from 'components/common/AudioPlayer';
import FeedbackButton from 'components/onDemand/Feedback/FeedbackButton';
import { useSnackbar } from 'contexts/snackbarContext';
import {
  CONTENT_TILE_ICONS,
  getCompletionEventName,
  getOnDemandClickEventName,
  getOnDemandEventLocation,
  getWistiaIdFromUrl,
} from '../utils';
import AttributeDetails from './AttributeDetails';
import useSendThrottledProgress, {
  MINIMAL_ACCEPTABLE_PROGRESS,
} from '../../../hooks/useSendThrottledProgress';
import FeedbackModal from '../Feedback/FeedbackModal';
import InlineFeedback from '../Feedback/InlineFeedback';

const CONTAINER_WIDTH = 800;
const CONTENT_WIDTH = 640;

const HERO_CONTAINER_HEIGHTS = {
  Audio: 277,
  Video: 450,
  Article: 389,
};

const DetailsWrapper = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.alias.mainSurface,
  height: '100%',
  overflowY: 'auto',
}));

const CenterFlexContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
});

const ContentWrapper = styled(CenterFlexContainer)({
  maxWidth: CONTAINER_WIDTH,
});

const ContainerStyled = styled(Container)(({ theme }) => ({
  backgroundColor: theme.colors.alias.mainBackground,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  marginTop: theme.baseUnit * 4,
  paddingBottom: theme.baseUnit * 10,
}));

const GenrePill = styled(Typography)(({ theme }) => ({
  backgroundColor: theme.colors.alias.mainSurface,
  borderRadius: 16,
  color: theme.colors.alias.textPrimary,
  ...theme.typography.textVariant.label.l,
  padding: `${theme.baseUnit}px 12px`,
  marginTop: theme.baseUnit * 3,
}));

const Title = styled(Typography)(({ theme }) => ({
  ...theme.typography.textVariant.title.xl,
  color: theme.colors.alias.textPrimary,
  margin: theme.baseUnit * 3,
  textAlign: 'center',
}));

const Description = styled(Typography)(({ theme }) => ({
  ...theme.typography.textVariant.body.l,
  color: theme.colors.alias.textSecondary,
  margin: `${theme.baseUnit * 5}px 0`,
}));

const RelatedWrapper = styled(CenterFlexContainer)({
  maxWidth: CONTAINER_WIDTH,
});

const RelatedTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.textVariant.title.s,
  color: theme.colors.alias.textPrimary,
  marginTop: theme.baseUnit * 5,
  marginBottom: theme.baseUnit * 2,
}));

const RecommendationsRow = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  gap: theme.baseUnit,
  marginBottom: theme.baseUnit * 10,
}));

const SubtitleWrapper = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.baseUnit,
}));

const SubtitleItem = styled.span(({ theme }) => ({
  ...theme.typography.textVariant.subtitle.m,
  fontFamily: 'reader400',
  color: theme.colors.alias.textSecondary,
}));

const VideoContainer = styled.div({
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
});

const SubtitleSeparator = styled.div(({ theme }) => ({
  height: 32,
  width: 1,
  backgroundColor: theme.colors.alias.mainSurfaceDivider,
}));

const HeroContainer = styled.div(({ theme }) => ({
  width: CONTAINER_WIDTH,
  marginTop: theme.baseUnit * 3,
  position: 'relative',
}));

const HeroPhoto = styled.img({
  width: CONTAINER_WIDTH,
  height: '100%',
});

const ContentPage = () => {
  const theme = usePlaybookTheme();
  const track = useMixpanel();
  const params = useParams();
  const { contentId } = params;

  const [maxProgress, setMaxProgress] = React.useState(0);
  const [initialFeedback, setInitialFeedback] = React.useState<
    FeedbackKind | null | undefined
  >(null);
  const [feedbackModalOpen, setFeedbackModalOpen] = React.useState(false);

  const { data, loading } = useGetOnDemandDetailsQuery({
    skip: !contentId,
    variables: {
      id: contentId ?? '',
    },
  });
  const location = useLocation();
  const navigate = useNavigate();
  const snackbar = useSnackbar();

  const content = data?.content;
  const genre = data?.content?.contentGenre?.name;
  const type = content?.__typename;
  const iconType = type && CONTENT_TILE_ICONS[type.toLowerCase()];
  const recommendations = data?.content?.recommendations.nodes ?? [];
  const wistiaId =
    type === 'Video' && content?.video
      ? getWistiaIdFromUrl(content?.video)
      : '';

  const isDraft = content?.isPublished === false;

  const hasCompleted = maxProgress > MINIMAL_ACCEPTABLE_PROGRESS;
  const hasFeedback =
    content?.feedback && content.feedback !== FeedbackKind.Needed;
  const ratingNeededBlocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      hasCompleted &&
      !hasFeedback &&
      currentLocation.pathname !== nextLocation.pathname,
  );

  const contentIsSupported =
    type === 'Video' || type === 'Audio' || type === 'Article';

  const progressMixPanelData = useMemo(
    () => ({
      event_category: 'on demand',
      event_location: getOnDemandEventLocation(type),
      event_name: getCompletionEventName(type),
    }),
    [type],
  );

  const sendThrottledProgress = useSendThrottledProgress();

  const onProgress = (progress: number) => {
    if (isDraft || !contentId) {
      return;
    }

    setMaxProgress(Math.max(maxProgress, progress));

    const shouldTrackCompletionEvent = type !== 'Video'; // the video player already tracks the completion event
    sendThrottledProgress(
      progress,
      contentId,
      progressMixPanelData,
      shouldTrackCompletionEvent,
    );
  };

  const handleFeedbackClosed = useCallback(
    (newFeedback: FeedbackKind | null) => {
      setFeedbackModalOpen(false);

      if (newFeedback && initialFeedback !== newFeedback) {
        snackbar.show('Thanks for rating!', 2000);
      }

      if (ratingNeededBlocker.state === 'blocked') {
        setMaxProgress(0);
        ratingNeededBlocker.proceed();
      }
    },
    [ratingNeededBlocker, initialFeedback, snackbar],
  );

  const heroPhotoSrc =
    content?.heroPhoto &&
    computeUrl(content.heroPhoto, {
      width: CONTAINER_WIDTH,
      height: CONTAINER_WIDTH / 2,
    });

  useEffect(() => {
    if (content?.id && contentIsSupported) {
      const pageName =
        content.__typename === 'Article' ? 'article' : 'class_on_demand_detail';
      track('Screen View', {
        page_name: pageName,
        content_id: content.id,
      });
    }
  }, [track, content?.id, content?.__typename, contentIsSupported]);

  const trackRecommendationClick = useCallback(
    (
      recommendationId: string,
      recommendationType: OnDemandDetailsFragment['__typename'],
    ) => {
      track('User Action', {
        event_category: 'on demand',
        event_location: getOnDemandEventLocation(recommendationType),
        event_subcategory: 'related',
        event_type: 'passive engagement',
        event_name: getOnDemandClickEventName(recommendationType),
        content_id: recommendationId,
      });
    },
    [track],
  );

  // if somehow we have a content id we not support here, we redirect to the QR Code Redirect page
  // so the user can open the Exos app on their mobile device
  if (content?.__typename && !contentIsSupported) {
    navigate(`${QR_REDIRECT_PATH}${location.pathname}`);
    return null;
  }

  return (
    <Modal open>
      <DetailsWrapper>
        <Header />
        {loading && (
          <CircularProgress
            color="inherit"
            style={{ margin: '100px auto', display: 'block' }}
            aria-label="Loading"
          />
        )}
        {contentIsSupported && !loading && (
          <CenterFlexContainer>
            <ContainerStyled>
              {genre && <GenrePill>{genre}</GenrePill>}

              <ContentWrapper>
                <Title>{content?.title}</Title>
                <SubtitleWrapper>
                  {iconType && (
                    <FontAwesomeIcon
                      size="lg"
                      key="icon"
                      icon={iconType}
                      style={{ paddingBottom: 4 }}
                    />
                  )}
                  <SubtitleItem>
                    {Math.floor(Number(content?.time))} min
                  </SubtitleItem>
                  {content?.author && (
                    <SubtitleItem>{` · ${content?.author}`}</SubtitleItem>
                  )}

                  <SubtitleSeparator />
                  {content?.id && (
                    <CopyButton
                      id={content.id}
                      inverted
                      size={48}
                      mixpanelData={{
                        event_category: 'on demand',
                        event_location: getOnDemandEventLocation(type),
                      }}
                    />
                  )}
                  {content?.id && (
                    <FeedbackButton
                      id={content.id}
                      currentFeedback={content.feedback}
                      onClick={() => {
                        if (content) {
                          setInitialFeedback(content.feedback);
                        }
                        setFeedbackModalOpen(true);
                      }}
                      containerSize={48}
                      iconSize={15}
                      inverted
                      mixpanelData={{
                        event_category: 'on demand',
                        event_location: getOnDemandEventLocation(type),
                      }}
                    />
                  )}
                  {content?.id && typeof content?.saved === 'boolean' && (
                    <BookmarkButton
                      contentId={content.id}
                      isSaved={content?.saved}
                      containerSize={48}
                      iconSize={15}
                      inverted
                      mixpanelData={{
                        event_category: 'on demand',
                        event_location: getOnDemandEventLocation(type),
                      }}
                    />
                  )}
                </SubtitleWrapper>

                <HeroContainer style={{ height: HERO_CONTAINER_HEIGHTS[type] }}>
                  {type === 'Video' && contentId && content?.video ? (
                    <VideoContainer test-id={`video-${content.video}`}>
                      <WistiaPlayer
                        autoFocus={false}
                        hashedId={wistiaId}
                        videoId={contentId}
                        videoFoam={false}
                        onPause={onProgress}
                        onProgress={onProgress}
                        mixPanelData={{
                          event_category: 'on demand',
                          event_location: getOnDemandEventLocation(type),
                        }}
                      />
                    </VideoContainer>
                  ) : (
                    heroPhotoSrc && (
                      <HeroPhoto
                        src={heroPhotoSrc}
                        alt={content?.title ?? ''}
                      />
                    )
                  )}
                </HeroContainer>

                {type === 'Audio' && content && (
                  <AudioPlayer
                    audio={content?.audio}
                    transcript={content.transcript}
                    onProgress={onProgress}
                    width={CONTAINER_WIDTH}
                    mixpanelData={{
                      event_category: 'on demand',
                      event_location: getOnDemandEventLocation(type),
                    }}
                  />
                )}
                {type === 'Article' && content?.html && (
                  <div style={{ marginTop: theme.baseUnit * 5 }}>
                    <HtmlContent
                      html={content?.html}
                      containerWidth={CONTAINER_WIDTH}
                      contentWidth={CONTENT_WIDTH}
                      onProgress={onProgress}
                    />
                    {!hasFeedback && (
                      <>
                        <Divider
                          variant="fullWidth"
                          style={{
                            marginTop: theme.baseUnit * 5,
                            marginBottom: theme.baseUnit * 5,
                          }}
                        />
                        <InlineFeedback
                          contentId={contentId}
                          type={type}
                          currentFeedback={content.feedback}
                        />
                      </>
                    )}
                  </div>
                )}

                {['Video', 'Audio'].includes(type) && content && (
                  <div style={{ maxWidth: 600 }}>
                    {/* eslint-disable-next-line react/no-danger */}
                    <Description
                      dangerouslySetInnerHTML={{ __html: content?.html ?? '' }}
                    />
                    <AttributeDetails content={content} />
                  </div>
                )}
              </ContentWrapper>
            </ContainerStyled>

            {recommendations.length > 0 && (
              <RelatedWrapper>
                <div>
                  <RelatedTitle>Related</RelatedTitle>
                  <RecommendationsRow>
                    {recommendations.map((item) => (
                      <ClassTile
                        key={item.id}
                        contentId={item.id}
                        onClick={() => {
                          setMaxProgress(0);
                          trackRecommendationClick(item.id, item.__typename);
                        }}
                        title={item.title ?? ''}
                        image={item.heroPhoto}
                        mixpanelData={{
                          event_subcategory: 'related',
                          event_location: getOnDemandEventLocation(
                            item.__typename,
                          ),
                        }}
                        time={item.time}
                        genre={item.contentGenre?.name}
                        level={item.contentLevel?.name}
                        type={item.__typename}
                        saved={item.saved}
                      />
                    ))}
                  </RecommendationsRow>
                </div>
              </RelatedWrapper>
            )}
          </CenterFlexContainer>
        )}
        {contentIsSupported && (
          <FeedbackModal
            open={feedbackModalOpen || ratingNeededBlocker.state === 'blocked'}
            onClose={handleFeedbackClosed}
            contentId={contentId}
            type={type}
            currentFeedback={content?.feedback}
          />
        )}
      </DetailsWrapper>
    </Modal>
  );
};

export default ContentPage;
