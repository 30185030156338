import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import { useSnackbar } from 'contexts/snackbarContext';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from '@emotion/styled';
import usePlaybookTheme from 'playbook/hooks/usePlaybookTheme';

const StyledSnackbar = styled('div')(() => {
  const theme = usePlaybookTheme();

  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.baseUnit,
    backgroundColor:
      theme.currentTheme === 'dark'
        ? theme.colors.alias.contrast
        : theme.colors.alias.primary,
    padding: theme.baseUnit * 2,
    paddingRight: theme.baseUnit * 4,
    elevation: 4,
    shadowColor: theme.colors.alias.primaryPressed,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.23,
    shadowRadius: 2.62,
  };
});

const StyledText = styled('span')(() => {
  const theme = usePlaybookTheme();

  return {
    ...theme.typography.fontFamily.sharp600,
    fontSize: '14px',
    paddingLeft: '4px',
    marginTop: '-2px',
    lineHeight: 1,
    color:
      theme.currentTheme === 'dark'
        ? theme.colors.alias.primary
        : theme.colors.alias.textContrast,
  };
});

const SnackBar = () => {
  const { state } = useSnackbar();
  const theme = usePlaybookTheme();

  return (
    <Snackbar
      style={{
        zIndex: 9999,
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={state.visible}
    >
      <StyledSnackbar>
        <FontAwesomeIcon
          icon={faCheckCircle}
          color={theme.colors.alias.secondary}
          height={24}
          width={24}
        />
        <StyledText>{state.text}</StyledText>
      </StyledSnackbar>
    </Snackbar>
  );
};

export default SnackBar;
